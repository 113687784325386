import Shepherd from "shepherd.js"
import { UserStorage } from "#js/components/storages"
import { addToCart } from "#js/components/tracking"

const userStorage = new UserStorage()

/**
 * Like a confirm dialog, but it opens a modal and connects the callback to a button.
 * @param {string} format -- The format to show the briefing for.
 * @param {Array} briefing -- The briefing to show.
 * @param {object} itemAnalytics -- The analytics object for the item.
 * @param {Function} callback -- The function to call when the user clicks the button.
 * @param {boolean} isInstantBooking -- Whether the booking is an instant booking.
 * @returns {Promise<void>} -- A promise that resolves when the user clicks the button.
 */
export async function formatBriefing(
  format,
  briefing,
  itemAnalytics,
  callback,
  isInstantBooking = false,
) {
  if (!globalThis.userId) {
    callback()
    return
  }

  if (Shepherd.activeTour) {
    callback()
    return
  }

  if (typeof itemAnalytics === "string") {
    itemAnalytics = JSON.parse(itemAnalytics)
  }
  const modalElement = document.getElementById("format-briefing")
  const hasBriefing = briefing && JSON.parse(briefing).length > 0
  const formatsToABTest = ["recording", "e-learning", "guide"]
  if (
    await userStorage.getItem(`format-briefing-${format}`) || !hasBriefing ||
    (globalThis.userTestGroup === "B" && formatsToABTest.includes(format))
  ) {
    if (!isInstantBooking) {
      addToCart(itemAnalytics)
    }
    await callback()
  } else {
    addToCart(itemAnalytics)
    document.getElementById("format-briefing-submit").addEventListener(
      "click",
      async () => {
        await userStorage.setItem(`format-briefing-${format}`, true)
        await callback()
        modalElement.close()
      },
    )

    modalElement.showModal()
  }
}
